export interface Channel {
  slug: string;
  title: string;
}

export const channels: Record<string, Channel> = {
  "mandarin-corner": {
    slug: "mandarin-corner",
    title: "Mandarin Corner",
  },
  "tea-time-chinese": {
    slug: "tea-time-chinese",
    title: "TeaTime Chinese",
  },
  "chinese-podcast-shenglan": {
    slug: "chinese-podcast-shenglan",
    title: "Chinese Podcast With Shenglan",
  },
  "comprehensible-mandarin": {
    slug: "comprehensible-mandarin",
    title: "Comprehensible Mandarin",
  },
  "hello-chinese": {
    slug: "hello-chinese",
    title: "Hello Chinese",
  },
  "comprehensible-chinese": {
    slug: "comprehensible-chinese",
    title: "Comprehensible Chinese",
  },
  "climb-with-me": {
    slug: "climb-with-me",
    title: "Climb With Me",
  },
};

export default channels;

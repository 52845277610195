import React from "react";
import { Channel } from "../data/channels";

interface Props {
  channel: Channel;
}

export const ChannelInfo = ({ channel }: Props) => {
  return <p>{channel.title}</p>;
};

export default ChannelInfo;

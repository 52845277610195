import React from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";

import { Video } from "../data/videos";
import channels, { Channel } from "../data/channels";
import ChannelInfo from "./ChannelInfo";
import DifficultyIcon from "./DifficultyIcon";

interface Props {
  video: Video;
  viewed: boolean;
}

// Display a single matched video, in the context of a search / filter
// operation.
export const SearchResult = ({ video, viewed }: Props) => {
  let channel: Channel | undefined;
  if (video.channel !== undefined) {
    channel = channels[video.channel];
  }

  return (
    <Container className="searchResult">
      <Row>
        <Col sm={12} lg={6}>
          <Link to={`/video/${video.slug}/`}>
            <img
              src={`https://mandarinput.com/images/thumbnails/${video.videoId}.jpg`}
              className="coverImage"
            />
          </Link>
        </Col>
        <Col sm={12} lg={6}>
          <Link to={`/video/${video.slug}/`}>
            <h2>{video.title}</h2>
          </Link>
          {channel !== undefined && <ChannelInfo channel={channel} />}

          {video.difficulty !== undefined && (
            <div>
              Difficulty: <DifficultyIcon difficulty={video.difficulty} />
            </div>
          )}

          {viewed && <Badge bg="primary">Watched</Badge>}
        </Col>
      </Row>
    </Container>
  );
};

export default SearchResult;

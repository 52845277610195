// 1: Extremely slow
// 2: Slow, not natural
// 3: Natural but noticeably slow
// 4: Natural
// 5: Fast natural
export type SpeechSpeed = 1 | 2 | 3 | 4 | 5;

export interface Subtitles {
  english: "yes" | "no" | "optional";
  chinese: "yes" | "no" | "optional";
  pinyin: "yes" | "no" | "optional";
}

export interface Video {
  slug: string;
  videoId: string;
  title: string;
  channel?: string;
  topics: readonly string[];
  hsk?: number;
  duration: number;
  difficulty?: number;
  subtitles?: Subtitles;
  speechSpeed?: SpeechSpeed;
}

const isSubtitles = (x: {
  [K in keyof Subtitles]: string | null;
}): x is Subtitles => {
  return (
    (x.english === "yes" || x.english === "no" || x.english === "optional") &&
    (x.chinese === "yes" || x.chinese === "no" || x.chinese === "optional") &&
    (x.pinyin === "yes" || x.pinyin === "no" || x.pinyin === "optional")
  );
};

const speechSpeedFromNumber = (
  value: number | null
): SpeechSpeed | undefined => {
  if (value === null) {
    return undefined;
  }

  if (value >= 1 && value <= 5) {
    return value as SpeechSpeed;
  }

  return undefined;
};

// A video as it is returned from the GraphQL query, which is mostly with some
// nullable stuff in it
interface VideoNode {
  videoId: string | null;
  slug: string | null;
  title: string | null;
  channel: string | null;
  topics: readonly (string | null)[] | null;
  duration: number | null;
  difficulty: number | null;
  speechSpeed: number | null;
  subtitles: {
    english: string | null;
    chinese: string | null;
    pinyin: string | null;
  } | null;
}

export const videoFromNode = (node: NonNullable<VideoNode>): Video | null => {
  if (
    node.slug !== null &&
    node.videoId !== null &&
    node.title !== null &&
    node.topics !== null
  ) {
    const topics: string[] = node.topics.filter((x): x is string => x !== null);

    return {
      slug: node.slug,
      videoId: node.videoId,
      title: node.title,
      channel: node.channel ?? undefined,
      topics,
      duration: node.duration ?? 0,
      difficulty: node.difficulty ?? undefined,
      subtitles:
        node.subtitles !== null && isSubtitles(node.subtitles)
          ? node.subtitles
          : undefined,
      speechSpeed: speechSpeedFromNumber(node.speechSpeed),
    };
  } else {
    return null;
  }
};

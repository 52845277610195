import React, { ReactElement } from "react";

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

export interface Props {
  difficulty: number;
}

export const DifficultyIcon = ({ difficulty }: Props) => {
  const elements: ReactElement[] = [];

  for (let i = 0; i < 5; i++) {
    if (difficulty >= i) {
      elements.push(<MdRadioButtonChecked key={i} />);
    } else {
      elements.push(<MdRadioButtonUnchecked key={i} />);
    }
  }

  return <div>{elements}</div>;
};

export default DifficultyIcon;

import { graphql, PageProps, HeadProps } from "gatsby";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import "../../styles/main.scss";
import Layout from "../../layouts/default";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";
import PageIcon from "../../components/PageIcon";

export const Head = ({ data }: HeadProps<Queries.BlogPostBySlugQuery>) => {
  return (
    <>
      <title>
        {data.markdownRemark?.frontmatter?.title ?? "Blog post"} | MandarInput
        Blog
      </title>
      <PageIcon />
    </>
  );
};

export const BlogPage = ({ data }: PageProps<Queries.BlogPostBySlugQuery>) => {
  const post = data.markdownRemark;
  const recentVideos = data.allVideosYaml.nodes;

  return (
    <>
      <Layout>
        <Row>
          <Col sm={12} lg={8}>
            <Card>
              <Card.Body>
                <h1>{post?.frontmatter?.title}</h1>

                <section
                  dangerouslySetInnerHTML={{ __html: post?.html ?? "" }}
                />
              </Card.Body>
            </Card>
          </Col>
          {recentVideos.length > 0 && (
            <Col sm={12} lg={4}>
              <h2>Most recent videos</h2>
              {recentVideos.map((videoNode) => {
                const video = videoFromNode(videoNode);
                if (video !== null) {
                  return (
                    <SearchResult
                      key={video.videoId}
                      video={video}
                      viewed={false}
                    />
                  );
                }
              })}
            </Col>
          )}
        </Row>
      </Layout>
    </>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }

    allVideosYaml(sort: { fields: [publishedAt], order: DESC }, limit: 5) {
      nodes {
        slug
        hsk
        duration
        channel
        topics
        title
        videoId
        subtitles {
          chinese
          english
          pinyin
        }
        speechSpeed
        difficulty
      }
    }
  }
`;
